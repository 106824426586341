<template>
  <div class="clearBox">
    <div class="content-box">
      <div class="content-main">
        <!-- 左侧非地图部分 -->
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
        >
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="合同名称" prop="contractName">
                <el-input v-model="form.contractName" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="合同编号" prop="contractNum">
                <el-input v-model="form.contractNum" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="中标日期" prop="getBidDate">
                <el-date-picker
                  v-model="form.getBidDate"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="合同金额" prop="contractFund">
                <el-input v-model="form.contractFund" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="合同签订方式" prop="signType">
                <el-select v-model="form.signType" placeholder="请选择" filterable>
                  <el-option
                    v-for="item in accidentTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="合同年限" prop="contractLimit">
                <el-select v-model="form.contractLimit" placeholder="请选择" filterable>
                  <el-option
                    v-for="item in contractLimitOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="服务开始日期" prop="contractStartTime">
                <el-date-picker
                  v-model="form.contractStartTime"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="服务终止日期" prop="contractEndTime">
                <el-date-picker
                  v-model="form.contractEndTime"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="标段名称" prop="bidId">
                <el-cascader
                  v-model="form.bidId"
                  placeholder="请选择"
                  :options="bidList"
                  :show-all-levels="false"
                  filterable
                  @change="hanldBidId"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="甲方单位" prop="partyAUnit">
                <el-select v-model="form.partyAUnit" placeholder="请选择" filterable>
                  <el-option
                    v-for="item in listFirstPartyOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="甲方项目联系人" prop="partyAContact">
                <el-input v-model="form.partyAContact" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="甲方联系方式" prop="partyAPhone">
                <el-input v-model="form.partyAPhone" placeholder="请输入" maxlength="11"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="乙方项目联系人" prop="partyBContact">
                <el-input v-model="form.partyBContact" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="乙方联系方式" prop="partyBPhone">
                <el-input v-model="form.partyBPhone" placeholder="请输入" maxlength="11"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="所属企业" prop="companyId">
                <el-input v-model="form.companyId" placeholder="请输入" maxlength="25" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="合同附件上传" prop="imageUrls">
                <ayl-img-upload
                  v-model="imageUrls"
                  :imgWidth="'120px'"
                  :imgHeight="'120px'"
                  :imgListLength="50"
                ></ayl-img-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <!-- <el-button class="btn-hw" @click="create_order(1)">保存并录入下一条</el-button> -->
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose()">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    const vm = this;
    return {
      nav: [{ name: "编辑合同" }],

      submitLoading: false,
      contractLimitOptions: [
        { value: 0, label: "一年" },
        { value: 1, label: "两年" },
        { value: 2, label: "三年" }
      ],

      accidentTypeOptions: [
        { value: 0, label: "一年一签" },
        { value: 1, label: "两年一签" },
        { value: 2, label: "三年一签" },
        { value: 3, label: "三+二续约" }
      ],
      // 标段名称下拉
      bidList: [],
      // 甲方合同下拉
      listFirstPartyOption: [],

      form: {
        contractName: null, //合同名称
        contractNum: null, //合同编号
        getBidDate: null, //中标日期
        contractFund: null, //合同金额
        signType: null, //合同签订方式
        contractLimit: null, //合同年限
        contractStartTime: null, //服务开始日期
        contractEndTime: null, //服务终止日期
        partyAUnit: null, //甲方单位
        bidId: null, //标段名称
        partyAContact: null, //甲方项目联系人
        partyAPhone: null, //甲方联系方式
        partyBContact: null, //乙方项目联系人
        partyBPhone: null, //乙方联系方式
        companyId: null, //所属企业
        contractImageUrls: null //合同附件
      },
      imageUrls: [],
      rules: {
        contractName: {
          required: true,
          message: "请输入合同名称",
          trigger: "blur"
        },
        contractNum: {
          required: true,
          message: "请输入合同编号",
          trigger: "blur"
        },
        getBidDate: {
          required: true,
          message: "请输入合同名称",
          trigger: "change"
        },
        contractFund: {
          required: true,
          message: "请输入合同金额",
          trigger: "blur"
        },
        // signType: {
        //   required: true,
        //   message: "合同签订方式",
        //   trigger: "change"
        // },
        contractLimit: {
          required: true,
          message: "请选择合同年限",
          trigger: "change"
        },
        contractStartTime: {
          required: true,
          message: "请选择服务开始日期",
          trigger: "change"
        },
        contractEndTime: {
          required: true,
          message: "请选择服务终止日期",
          trigger: "change"
        },
        partyAUnit: {
          required: true,
          message: "请选择甲方单位",
          trigger: "change"
        },
        bidId: {
          required: true,
          message: "请选择标段名称",
          trigger: "change"
        },
        partyAContact: {
          required: true,
          message: "请输入甲方项目联系人",
          trigger: "blur"
        },
        partyAPhone: {
          required: true,
          message: "请输入甲方联系方式",
          trigger: "blur"
        },
        partyBContact: {
          required: true,
          message: "请输入乙方项目联系人",
          trigger: "blur"
        },
        partyBPhone: {
          required: true,
          message: "请输入乙方联系方式",
          trigger: "blur"
        },
        companyId: {
          required: true,
          message: "请输入所属企业",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    async hanldBidId(arr) {
      this.form.bidId = arr[1];
      // 甲方合同下拉
      this.listFirstPartyOption = await this.$api_hw.common_listFirstParty({
        bidId: arr[1]
      });
    },
    //关闭
    onClose() {
      this.$emit("onClose"); //关闭弹窗
    },

    async submit() {
      let formData = JSON.parse(JSON.stringify(this.form));
      formData.contractImageUrls = [...this.imageUrls];
      delete formData.gmtModified;
      delete formData.gmtCreate;
      let params = {
        contractInfo: formData
      };
      await this.$api_hw.districtInfo_contractUpdate(params).then(res => {
        this.$notify({
          title: "成功",
          message: `${this.nav[0].name}成功`,
          type: "success"
        });
        BUS.$emit(BUSEVENT.REFRESH_DISTRICT_CONTRACT);
        this.onClose();
        this.$refs["form"].resetFields();
      });
    },
    // 提交表单
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        this.submitLoading = true;
        try {
          this.submit();
        } catch (e) {
          console.log(e);
        } finally {
          this.submitLoading = false;
        }
      });
    }
  },

  computed: {},
  async mounted() {
    //获取所属标段下拉
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});

    // 所属企业
    // let Odata = await this.$api_hw.vehicleManagement_getByTenantId({});
    // this.form.companyId = Odata.company;
    // 获取详情
    await this.$api_hw
      .districtInfo_contractGet({
        id: this.dataFromDialog.id
      })
      .then(res => {
        this.form = res;
        this.hanldBidId(["", this.form.bidId]);
      });
    this.imageUrls = this.form.contractImageUrls;
  }
};
</script>

<style lang='sass' scoped>
.el-dialog__body
  padding-top: 0 !important
.content-box 
  padding: 0


/deep/ el-form
  display: flex;
  flex-wrap: wrap;
/deep/ .el-form-item
  display: flex;
  width: 100%;
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner,
/deep/ .el-cascader--mini,
/deep/ .el-select
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
/deep/ .el-form-item--mini .el-form-item__label 
  text-align: right;
  width: 130px;
</style>
